<script>
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ProcedureService from "@/services/procedure-service";
import PatientService from "@/services/patient-service";
import ProfessionalService from "@/services/professional-service";
import RoomService from "@/services/room-service";
import PaymentTypeService from "@/services/paymenttype-service";
/**
 * Procedures Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
  },
  data() {
    return {
      pacientes: {},
      title: "Procedures",
      items: [
        {
          text: "Atendimentos",
        },
        {
          text: "Procedures",
          active: true,
        },
      ],
      proceduresData: [],

      submitted: false,
      showmodal: false,
      //Paginacao
      pagination: {
        numberOfElements: 0,
        totalPages: 0,
        totalElements: 0,
        last: true,
        first: true,
        number: 0,
        size: 10,
      },
      paginate: false,

      params: {
        size: 100,
        unitIds: "3,5",
      },
      units: [1,2,3,4],
      DPDateTime: new Date(new Date().setHours(7, 0, 0, 0)),
      
      //Modal 
      paymentStatus: ["OPEN","PAIED","BILLED","CANCELED","GLOSSED","RECURSED"],
      statusList: ["SCHEDULED", "STARTED", "FINISHED", "CANCELED","MISS","DELETED"],
      procedure: {},
      resultPatientIsOpen: false,
      inputFindPatient: "",
      resultsPatientFind: [],
      patient: {},

      resultProfessionalIsOpen: false,
      inputFindProfessional: "",
      resultsProfessionalFind: [],
      professional: {},
      rooms: [],
      paymentTypes: [],
      

      filters: {
        dateStart: new Date(new Date().setHours(0, 0, 0, 0)),
        professionalFilter: "",
        patientFilter: "",

      },

      mapaSalas: {teste:"ok"},
      horarios: [
      ],
    };
  },

  

  validations: {
    procedure: {
      patient: { required },
      professional: { required },
      room: { required },
    },
  },
  created() {
    this.resetProcedure();
    this.service = new ProcedureService(this.$http);
    this.patientService = new PatientService(this.$http);
    this.professionalService = new ProfessionalService(this.$http);
    this.roomService = new RoomService(this.$http);
    this.paymentTypeService = new PaymentTypeService(this.$http);
    this.chargeRooms();
    this.chargeItens();
    this.chargePaymentTypes();
    
    
  },
  methods: {
    chargePaymentTypes() {
      console.log("chargePaymentTypes")
      this.paymentTypeService.list({size:100}).then((res) => {

        console.log("listPaymenttypes", res);
        this.paymentTypes = res.data.content;
        console.log(this.paymentTypes);
      }),
        (err) => {
          console.error(err);
        };
    },
    chargeRooms() {
      this.roomService.list({ size: 100 }).then((res) => {
        console.log(res);
        this.rooms = res.data.content;
      }),
        (err) => {
          console.error(err);
        };
    },
    createHours(){
      this.horarios=[];
      var horazero = new Date(this.params.dateStart.setHours(7,0,0,0))
      for(var i = 0;i<14;i++){
        this.horarios.push(horazero);
        horazero = new Date(horazero.getTime() + 60*60000);
      }


    }
    ,
    chargeItens() {
      this.loading = true;
      this.params.dateStart = new Date(this.filters.dateStart.setHours(0,0,0,0));
      this.createHours();
      this.params.dateEnd = new Date(this.filters.dateStart.setHours(23,59,59,0));
      this.service.list(this.params).then((res) => {
        console.log(res);
        this.proceduresData = res.data.content;

        this.pagination.totalPages = res.data.totalPages;
        this.pagination.totalElements = res.data.totalElements;
        this.pagination.last = res.data.last;
        this.pagination.first = res.data.first;
        this.pagination.number = res.data.number;
        this.pagination.size = res.data.size;
        this.pagination.numberOfElements = res.data.numberOfElements;

        this.paginate = res.data.totalPages > 0;
        this.mapa();
        this.loading = false;
      }),
        (err) => {
          console.error(err);
        };
    },
    changeSize() {
      this.params.page = 0;
      this.chargeItens();
    },
    gotoPage(pageNum) {
      this.params.page = pageNum;
      if (this.search && this.search.length > 2) {
        this.find();
      } else {
        this.chargeItens();
      }
    },
    edit(procedureItem) {
      const procedure = Object.assign({}, procedureItem);

      let date = new Date(Date.parse(procedure.dateTimeStart));
      procedure.dateTimeStart = date;
      this.inputFindPatient = procedure.patient.name;
      this.inputFindProfessional = procedure.professional.name;
      this.procedure = procedure;
      this.showmodal = true;
    },
    canceled(item){
      item.status = "CANCELED"
      this.save(item)
    },
    confirmed(item){
      item.status = "FINISHED"
      this.save(item)
    },
    save(item){
      this.service
          .save(item)
          .then((ret) => {
            console.log(ret);
            this.$toast.success("Salvo com sucesso!");
            this.chargeItens();
          });
    },
    handleItem(){
      const id = this.procedure.id;
        const patient = this.procedure.patient;
        const professional = this.procedure.professional;
        const room = this.procedure.room;
        const dateTimeStart = this.DPDateTime;
        const status = this.procedure.status;
        const paymentStatus = this.procedure.paymentStatus;
        const paymentType = this.procedure.paymentType;

        var item = {id,
            patient,
            professional,
            room,
            dateTimeStart,
            status,
            paymentStatus,
            paymentType};

        return item;
    },

    resetProcedure(){
        this.procedure = {
          status: "SCHEDULED",
          paymentStatus: "OPEN"
        }
        this.inputFindPatient = "";
        this.inputFindProfessional = "";
    },
    validateForm(){
      if(this.$v.$invalid){
        

        this.submitted = false;
        this.$toast.error("Dados invalidos, verifique e tente novamente");
        return false;
      }
      return true;
    }
    ,
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      
      if (!this.validateForm()) {
        
        return
      } else {
        this.save(this.handleItem())
        this.showmodal = false;
        this.resetProcedure()
      }
      this.submitted = false;
      this.resetProcedure();
    },
    saveAndRepeat() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
       if (!this.validateForm()) {
        return
      } else {
        this.save(this.handleItem())
        
      }
      this.submitted = false;
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.resetProcedure();
    },

    /**
     * Filter the data of search
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    /**
     *  Busca Paciente
     */
    onChangePatientFind(e) {
      this.resultPatientIsOpen = true;
      if (e.srcElement.value.length < 3) {
        this.procedure.patient = null;
        clearTimeout(this.lastcall);
        this.lastcall = setTimeout(() => {
          this.filterResultsPatient();
        }, 1000);
      } else {
        this.lastcall;
        this.filterResultsPatient();
      }
    },
    filterResultsPatient() {
      console.log("filter ");
      this.procedure.patient = null;
      this.procedure.paymentType = null;
      this.patientService.listByName(this.inputFindPatient).then((ret) => {
        this.resultsPatientFind = ret.data.content;
      });
    },
    setResultPatient(result) {
      this.procedure.patient = result;
      this.procedure.paymentType = this.procedure.patient.paymentType
      this.inputFindPatient = this.procedure.patient.name;

      this.resultPatientIsOpen = false;
      this.$v.patient.$touch()
    },
    /**
     *  Busca Professional
     */
    onChangeProfessionalFind(e) {
      console.log(e);
      this.resultProfessionalIsOpen = true;

      if (e.srcElement.value.length < 3) {
        this.procedure.professional = null;
        clearTimeout(this.lastcall);
        this.lastcall = setTimeout(() => {
          this.filterResultsProfessional();
        }, 1000);
      } else {
        this.lastcall;
        this.filterResultsProfessional();
      }
    },
    filterResultsProfessional() {
      console.log("filter ");
      this.procedure.professional = null;
      this.professionalService
        .listByName(this.inputFindProfessional)
        .then((ret) => {
          this.resultsProfessionalFind = ret.data.content;
        });
    },
    setResultProfessional(result) {
      this.procedure.professional = result;
      this.inputFindProfessional = this.procedure.professional.name;
      this.resultProfessionalIsOpen = false;
      this.$v.professional.$touch()
    },

    //DatePiker
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notBeforeXDays(date) {
      let xdays = new Date(new Date().setHours(0,0,0,0));
      console.log(xdays)
      xdays.setDate(xdays.getDate() - 7);
      return date < xdays;
    },
    filtrar() {
      this.params.unitIds = this.units.map(Number).join(",")
      this.params.patientName = this.filters.patientFilter;
      this.params.professionalName = this.filters.professionalFilter;
      this.params.dateStart = this.filters.dateStart
      try{
          this.filters.dateEnd.setHours(23,59,59)
      }catch{
        console.log(this.filters.dateEnd)
      }
      this.params.paymentStatus
      this.params.dateEnd = this.filters.dateEnd
      this.params.page = 0
      this.chargeItens();
    },
    showPaymentType(paymentType){
      if (paymentType){
        return paymentType.name + " - (" + paymentType.type + ")"
      }else{
        return "";
      }
    },

    mapa(){
      var horarios = this.horarios
      var unidades = [...new Set(this.rooms.map(room => room.unit.name))];
      var unidadesPreenchidas = {} 
      unidades.forEach(unidade => {

        var salas = [...new Set(this.rooms.filter(room => room.unit.name == unidade).map(room => room.name))];

        console.log("Salas:"+salas);
        salas = salas.filter(sala => 
          this.proceduresData.some(procedimento => procedimento.room.name == sala));
        
          console.log("Salas:"+salas);

        
        var procedimentoshora = {}
        
        horarios.forEach(hora => {
          var salasPreenchidas = {}
          console.log(hora)

          salas.forEach(sala => {
            
            
            
            var procs =  this.proceduresData.filter(proc => proc.room.name == sala  && new Date(proc.dateTimeStart).getTime() == hora.getTime())

            procs.forEach(proc=>{
              console.log(new Date(proc.dateTimeStart) == new Date(hora))
              console.log(new Date(proc.dateTimeStart) )
              console.log(hora)
              console.log(new Date(proc.dateTimeStart).getTime())
              console.log(hora.getTime() )
            })
            console.log(procs)
            salasPreenchidas[sala] =  procs
                 
          });
          procedimentoshora[hora] = salasPreenchidas
         
         
          
        })
        console.log("Carregando unidade:" + unidade)
        unidadesPreenchidas[unidade] = procedimentoshora
        
        

      });
      
      
      console.log(unidadesPreenchidas)
      this.mapaSalas = unidadesPreenchidas
      console.log("FINALIZOU")

    }
  },
  computed: {
    roomsFiltred: function() {
      return this.rooms.filter( x => this.units.map(Number).indexOf(x.unit.id)>=0)
       
    }

  }
};
</script>

<template>
  <Layout>
    {{params}}
    <br>----<br>
    {{units}}
    <br>----<br>
    {{horarios}}
    <br>+++++<br>
    <PageHeader :title="title" :items="items" />
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a
                    v-b-toggle.accordion-3
                    class="text-dark"
                    href="javascript: void(0);"
                    >{{ $t("all.filters")}}  <i class="mdi mdi-filter-menu mr-2"></i></a
                  >
                </h6>
              </b-card-header>
              <b-collapse
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
                visible
              >
                <b-card-body class="row">
                  <div class="form-group col-md-6">
                    <label for="name" class="col-md-4">Data Inicio</label>
                    
                      <date-picker
                        
                        v-model="filters.dateStart"
                        format="DD/MM/YYYY HH:mm"
                        value-type="date"
                        type="date"
                        placeholder="DD/MM/YYYY HH:mm"
                        class="col-md-8"
                      ></date-picker>
                    
                  </div>
  
                  <div class="form-group  col-md-6">
                    <label for="name " class="col-md-5">Paciente</label>
                    <input
                      id="name"
                      v-model="filters.patientFilter"
                      type="text"
                      class=" col-md-7"
                      placeholder="Digite o nome"
                      
                    />
                  </div>
                  <div class="form-group  col-md-6" >
                    <label for="name" class="col-md-5">Profissional</label>
                    <input
                      id="name"
                      v-model="filters.professionalFilter"
                      type="text"
                      class="col-md-7"
                      placeholder="Digite o nome"
                     
                    />
                    
                  </div>
                  <!-- <div class="form-group row col-md-4" >
                    <label for="name" class="col-md-6">Alpes</label>
                    <b-checkbox
                      v-model="units"
                      value=1
                      class="col-md-2"
                    />
                  </div>
                  <div class="form-group row col-md-4" >
                    <label for="name" class="col-md-6">Almerim</label>
                    <b-checkbox
                      v-model="units"
                      value=2
                      class="col-md-2"
                    />
                  </div> -->
                  <div class="form-group row col-md-4" >
                    <label for="name" class="text-right col-md-6">SCS</label>
                    <b-checkbox
                      v-model="units"
                      value=3
                      class="col-md-2"
                      
                    />  
                  </div>
                  <div class="form-group row col-md-4" >
                    <label for="name" class="text-right col-md-6">Caneleiras</label>
                    <b-checkbox
                      v-model="units"
                      value=5
                      class="col-md-2"
                      
                    />  
                  </div>
                  <div class="text-right  col-md-12">
                    <b-button
                      type="button"
                      @click="filtrar"
                      variant="success"
                      class=""
                      >Filtrar</b-button
                    >
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="card-body">
            <div>
              <a
                href="javascript:void(0);"
                class="btn btn-success mb-2"
                @click="showmodal = true"
              >
                <i class="mdi mdi-plus mr-2"></i> Adicionar Novo
              </a>
            </div>
            <div class="table-responsive mt-3">
              
              <div v-for="(unidade, unidadekey) in mapaSalas " :key="unidade" >

                {{unidadekey}}

                <table border="1">
                  <tr >
                      <th>Hora</th >
                      <th v-for="(sala, salakey) in unidade[horarios[0]]" :key="salakey" nowrap>
                      {{salakey}}
                      
                      </th>
                  </tr>  
                  <tr v-for="(horario, horariokey) in unidade" :key="horariokey">                  
                    <td>
                      {{horariokey | formatDateTime}}
                    </td>
                    <td v-for="(sala, salakey) in horario" :key="salakey">
                      <div v-for="(procedimento, procedimentokey) in sala" :key="procedimentokey" class="col-md-12" style="min-width:200px">
                        <b-card
                        text-variant="white"
                        :title="procedimento.patient.name"
                        :sub-title="procedimento.professional.name"
                      >
                        
                      </b-card>
                      </div>
                      
                    </td>
                    
                  </tr> 
                                      
                </table>


                <hr>

              </div>
              
              


            </div>
          </div>
        </div>
      </div>
    </div>
   
  </Layout>
</template>